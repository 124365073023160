import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { scroll_handler } from "../utilities/scroll_handler.js";
import utility_data from "../utilities/browser_information.js";

export default class Message_Sent extends Component {
	constructor(props) {
		super(props);
		this.in_view = true
		this.state = {
			position: null
		};
	}

	componentDidMount() {
		const node = findDOMNode(this);
		setTimeout(function() {
			this.setState({
				position: node.getBoundingClientRect().top + (utility_data.browser_height / 4) + utility_data.scrollTop
			});
			this.scroll_handler = scroll_handler.bind(this);
			window.addEventListener('scroll', this.scroll_handler);
		}.bind(this), 200);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scroll_handler);
	}

	render() {
		let in_view = this.in_view ? ' in-view' : '';

		return(
			<div className={'message-sent title-description grid' + in_view}>
				<div className="interior-title-description grid-m-6 grid-t-16 push-d-1 grid-d-14">
					<h1 className="styling-eyebrow">Message Sent</h1>
					<div className="content-contain">
						<h3 className="styling-header-1">Thank you a member of our team will get back to you as soon as possible.</h3>
					</div>
				</div>
			</div>
		);
	}
}