import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { scroll_handler } from "../utilities/scroll_handler.js";
import utility_data from "../utilities/browser_information.js";

export default class TitleDescription extends Component {
	constructor(props) {
		super(props);
		this.in_view = true
		this.state = {
			position: null
		};
	}

	componentDidMount() {
		const node = findDOMNode(this);
		setTimeout(function() {
			this.setState({
				position: node.getBoundingClientRect().top + (utility_data.browser_height / 4) + utility_data.scrollTop
			});
			this.scroll_handler = scroll_handler.bind(this);
			window.addEventListener('scroll', this.scroll_handler);
		}.bind(this), 200);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scroll_handler);
	}

	createMarkup() {
		return {__html: this.props.data[0].acf['hero_description'] || this.props.data[0].acf['description']};
	}

	render() {
		let data = this.props.data[0].acf,
			in_view = this.in_view ? ' in-view' : '';

		return(
			<div className={'title-description grid' + in_view}>
				<div className="interior-title-description grid-m-6 grid-t-16 grid-d-16">
					<h1 className="styling-eyebrow">Start A Project</h1>
					<div className="content-contain">
						<h2 className="styling-header-1" dangerouslySetInnerHTML={this.createMarkup()} />
					</div>
				</div>
			</div>
		);
	}
}
